<template>
  <div class="reservation-info-page">
    <reservationStep :stepType="3" :stepNum="1"></reservationStep>
    <div class="step-content">
      <div class="info-list">
        <div class="info-list-title">本人信息</div>
        <div class="info-list-content">
          <div class="content-left">真实姓名</div>
          <!-- <Field
            class="content-right"
            v-model="userName"
            disabled
            placeholder="请输入用户名"
          /> -->
          <div class="content-right">{{ userName }}</div>
        </div>
        <div class="info-list-content">
          <div class="content-left">身份证号</div>
          <!-- <Field
            @blur="checkCardId(1)"
            class="content-right"
            v-model="userCardId"
            disabled
            maxlength="18"
            placeholder="请输入用身份证号"
          /> -->
          <div class="content-right">{{ userCardId }}</div>
        </div>
      </div>
      <div class="info-list">
        <div class="info-list-title">代领原因</div>
        <div class="info-list-content">
          <div
            class="content-item"
            :class="{ 'content-item-active': index === takeReasonIndex }"
            v-for="(item, index) in takeReasonList"
            :key="item"
            @click="chooseTakeReason(index)"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div class="info-list">
        <div class="info-list-title">代领人信息</div>
        <div class="info-list-content">
          <div class="content-left">真实姓名</div>
          <Field
            class="content-right"
            v-model="takeAuthName"
            @blur="checkUser"
            placeholder="请输入真实姓名"
          />
        </div>
        <div class="info-list-content">
          <div class="content-left">身份证号码</div>
          <Field
            class="content-right"
            v-model="takeAuthNum"
            @blur="checkUser"
            maxlength="18"
            placeholder="请输入身份证号码"
          />
        </div>
      </div>
      <div class="step-content-bottom">
        请填写正确的委托人信息，确认后将不允许修改。证书属于贵重物品，领取时老师会与代领人信息进行核对哦！
      </div>
      <div class="info-list">
        <div class="info-list-title">签约人信息</div>
        <div class="info-list-content">
          <div class="content-left">签约人姓名</div>
          <Field
              class="content-right"
              v-model="sign.authName"
              placeholder="请输入真实姓名"
          />
        </div>
        <div class="info-list-content">
          <div class="content-left">签约人证件号码</div>
          <Field
              class="content-right"
              v-model="sign.authNum"
              placeholder="请输入"
              disabled
          />
        </div>
        <div class="info-list-content">
          <div class="content-left">签约人手机号</div>
          <Field
              class="content-right"
              @blur="checkPhone"
              v-model="sign.mobile"
              placeholder="请输入"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="page-bottom">
    <div
      class="bottom-btn"
      :class="{
        'disabled-btn':
          takeReasonIndex == -1 ||
          userName == '' ||
          userCardId == '' ||
          takeAuthName == '' ||
          takeAuthNum == '',
      }"
      @click="confirmInfo"
    >
      完成代领信息填写
    </div>
  </div>
  <reservationDialog
    v-if="showDialog"
    :dialogType="3"
    :name="takeAuthName"
    :phone="takeAuthNum"
    @closeDialog="closeDialog"
    @submitDialog="saveInfo"
  ></reservationDialog>
</template>
<script setup>
import {onMounted, ref} from "vue";
import {Field, Toast} from "vant";
import {useRoute, useRouter} from "vue-router";
import reservationStep from "./components/reservationStep.vue";
import reservationDialog from "./components/reservationDialog.vue";
import {calendarData, getCertificateInfo} from "@/api/certificate";

const routeQuery = new useRoute();
const router = useRouter();
const userName = ref("");
const userCardId = ref("");
const takeAuthName = ref("");
const takeAuthNum = ref("");
const takeReasonList = ref(["人在外地", "时间冲突", "其他个人原因"]);
const takeReasonIndex = ref(-1);
const showDialog = ref(false);
const sign = ref({
  authName: "",
  mobile: "",
  authNum: ""
});
//证书ID
let id = routeQuery.query.id;
let updateinfo = routeQuery.query.updateinfo;
//选择代领原因
const chooseTakeReason = (e) => {
  takeReasonIndex.value = e;
};
// 获取信息
const getPageData = async () => {
  const res = await getCertificateInfo(id);
  if (res.code == 200) {
    for (let index = 0; index < takeReasonList.value.length; index++) {
      if (takeReasonList.value[index] == res.data.takeReason) {
        takeReasonIndex.value = index;
      }
      takeAuthName.value = res.data.takeAuthName;
      takeAuthNum.value = res.data.takeAuthNum;
    }
  }
};
const getUserData = async () => {
  const res = await calendarData(id);
  if (res.code == 200) {
    userName.value = res.data.authName;
    userCardId.value = res.data.authNum;
    sign.value.authName= res.data.authName;
    sign.value.authNum = res.data.authNum;
    sign.value.mobile = res.data.mobile;
  }
};
//确认信息
const confirmInfo = () => {
  if (
    takeReasonIndex.value == -1 ||
    userName.value == "" ||
    userCardId.value == "" ||
    takeAuthName.value == "" ||
    takeAuthNum.value == ""
  ) {
    return;
  }
  let status = checkUser();
  if (status) {
    return;
  }
  showDialog.value = true;
};
const closeDialog = () => {
  showDialog.value = false;
};
onMounted(() => {
  if (updateinfo == 1) {
    getPageData();
  }
  getUserData();
});
//保存信息
const saveInfo = () => {
  window.localStorage.setItem("takeAuthName", takeAuthName.value);
  window.localStorage.setItem("takeAuthNum", takeAuthNum.value);
  window.localStorage.setItem(
    "takeReason",
    takeReasonList.value[takeReasonIndex.value],
  );
  window.localStorage.setItem("sign", JSON.stringify(sign.value));
  router.push({
    name: "reservationOtherTime",
    query: {
      id: id,
      type: 3,
    },
  });
};
const checkUser = () => {
  if (takeAuthNum.value) {
    if (checkCardId(2)) {
      Toast("请输入正确的身份证号");
      return true;
    }
  }
  if (
    (takeAuthName.value && takeAuthName.value == userName.value) ||
    (takeAuthNum.value && takeAuthNum.value == userCardId.value)
  ) {
    Toast("请勿填写本人信息，如需本人领取，请选择[本人现场领取]领取方式");
    return true;
  }
};
const checkCardId = (type) => {
  let id = type == 1 ? userCardId.value : takeAuthNum.value;
  if (!checkId(id)) {
    Toast("请输入正确的身份证号");
    return true;
  }
};
const checkId = (id) => {
  const regex =
    /^[1-9]\d{5}(19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])\d{3}[\dxX]$/;
  return regex.test(id);
};
</script>
<style lang="scss" scoped>
.reservation-info-page {
  width: 100%;
  height: 110vh;
  background: #fafafa;
  box-sizing: border-box;
}
.step-content {
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  margin-top: 40px;
}
.info-list {
  width: 100%;
  padding: 0 30px 30px 30px;
  box-sizing: border-box;
  border-radius: 24px;
  background: #fff;
  margin-bottom: 30px;
  .info-list-title {
    font-size: 28px;
    color: #222;
    font-weight: 600;
    padding: 30px 0;
    border-bottom: 2px solid #eee;
  }
  .info-list-content {
    display: flex;
    align-items: center;
    font-size: 24px;
    color: #222;
    margin-top: 26px;
  }
  .content-right {
    flex: 1;
    padding: 0;
    text-align: right;
    :deep(.van-field__control) {
      font-size: 24px;
      color: #222;
      text-align: right;
    }
  }
}
.content-item {
  width: 188px;
  height: 54px;
  text-align: center;
  line-height: 54px;
  border-radius: 8px;
  border: 1px solid #999999;
  font-size: 24px;
  color: #999;
  margin-right: 30px;
}
.content-item:last-child {
  margin-right: 0;
}
.content-item-active {
  border: 1px solid #1e4290;
  color: #1e4290;
}
.step-content-bottom {
  width: 100%;
  padding: 0 30px;
  font-size: 24px;
  color: #999;
  box-sizing: border-box;
}
.page-bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 152px;
  background: #fff;
  padding: 20px 50px 0 50px;
  box-sizing: border-box;
  .bottom-btn {
    width: 100%;
    height: 88px;
    text-align: center;
    line-height: 88px;
    background: #1e4290;
    font-size: 28px;
    color: #fff;
    border-radius: 88px;
  }
  .disabled-btn {
    background: #8997b3;
  }
}
</style>
