<template>
  <Empty v-show="!dataLoaded">
    <template #description>
      <Loading
        color="#0094ff"
        text-color="#0094ff"
      >
        加载中...
      </Loading>
    </template>
  </Empty>

  <div
    v-show="dataLoaded"
    ref="statusInfoRef"
    :class="{ 'status-info': true, 'view': !editable }"
  >
    <Form
      ref="formRef"
      input-align="right"
    >
      <div
        v-if="basicInfoFields && basicInfoFields.length"
        class="section-title"
      >
        <div class="icon info" />
        <div class="text">
          基本信息
        </div>
      </div>
      <StatusFieldsHandler :fields="basicInfoFields" />
      <div
        v-if="(targetFields.includes('intentionPublic') || targetFields.includes('intentionSchool'))
          && formState && formState.intentionList && formState.intentionList.length"
        class="section-title"
      >
        <div class="icon intention" />
        <div class="text">
          报考意向
        </div>
      </div>
      <HdIntentionInfo :target-fileds="targetFields" />
      <div
        v-if="targetFields.includes('classInfoList') && formState && formState.classInfoList && formState.classInfoList.length"
        class="section-title"
      >
        <div class="icon education" />
        <div class="text">
          哈德学籍信息
        </div>
      </div>
      <HdEducationInfo :target-fileds="targetFields" />
      <div
        v-if="identityFields && identityFields.length"
        class="section-title"
      >
        <div class="icon identity" />
        <div class="text">
          证件信息
        </div>
      </div>
      <StatusFieldsHandler :fields="identityFields" />
      <div
        v-if="educationFields && educationFields.length"
        class="section-title"
      >
        <div class="icon cert" />
        <div class="text">
          学历证书信息
        </div>
      </div>
      <StatusFieldsHandler :fields="educationFields" />
      <div
        v-if="targetFields.includes('onlineBankingOpen')"
        class="section-title"
      >
        <div class="icon card" />
        <div class="text">
          银行卡信息
        </div>
      </div>
      <StatusFieldsHandler :fields="bankCardFields" />
      <div
        v-if="targetFields.includes('deliveryArea') || targetFields.includes('deliveryAddress')"
        class="section-title"
      >
        <div class="icon location" />
        <div class="text">
          物流地址信息
        </div>
      </div>
      <StatusFieldsHandler :fields="deliveryFields" />
      <div
        v-if="!route.params.userId"
        class="btn-block"
      >
        <Button
          v-if="editable"
          round
          block
          type="primary"
          class="btn"
          @click="onSubmit"
        >
          保存信息
        </Button>
        <Button
          v-if="!editable"
          class="btn"
          block
          @click="editable = true"
        >
          编辑信息
        </Button>
      </div>
    </Form>
    <!--dialogs-->
    <VanDialog
      v-model:show="showFilingsModal"
      :width="300"
      :show-confirm-button="false"
      :show-cancel-button="true"
      cancel-button-text="复制学信网链接"
      cancel-button-color="#157CFF"
      :close-on-click-overlay="true"
      theme="round-button"
      class="filings-dialog fixedpx"
      :teleport="statusInfoRef"
      @cancel="onCopyLink"
    >
      <div class="pop-filling-content">
        <img
          src="~@/assets/student/statusInfo/xx-title.png"
          width="300"
        >
        <img
          src="~@/assets/student/statusInfo/xx-content.png"
          width="300"
        >
      </div>
    </VanDialog>
    <VanDialog
      v-model:show="showConfirmModal"
      :show-confirm-button="true"
      confirm-button-text="确认提交"
      confirm-button-color="#1989fa"
      :show-cancel-button="true"
      cancel-button-text="再检查检查"
      cancel-button-color="#c8c9cc"
      title="提示"
      :before-close="onBeforeConfirm"
    >
      <div class="confirm-text">
        请确认所有信息是否填写正确，提交后部分信息不能修改哦~
      </div>
    </VanDialog>
  </div>
</template>
<script setup>
import { reactive, ref, computed, provide, onMounted } from 'vue';
import { Form, Button, Dialog, Toast, Empty, Loading } from 'vant';
import { useRoute } from "vue-router";
import useClipboard from 'vue-clipboard3'
import classSubject from "./classSubject.json"
import {
  getOrderInfoByUserId,
  submitStatusInfo,
  getStatusInfoByUserId,
  fetchNationOptions,
  fetchJobOptions
} from "@/api/statusInfo"
import { fetchUserInfo } from "@/api/user"
import StatusFieldsHandler from './components/StatusFieldsHandler.vue';
import HdEducationInfo from './components/HdEducationInfo.vue';
import HdIntentionInfo from './components/HdIntentionInfo.vue';
import { waitToken } from '@/utils/tools';
const ckFieldsDesc = classSubject.find(cs => cs.type == "ck");
//const gkFieldsDesc = classSubject.find(cs => cs.type == "gk");
const { toClipboard } = useClipboard()
const dataLoaded = ref(false);
const userShopItems = ref([]);
const VanDialog = Dialog.Component;
const userId = ref(0);
const statusInfoRef = ref();
const route = useRoute();
const nationOptions = ref([]);
const jobOptions = ref([]);
const fields = reactive({
  basicInfoFields: [
    {
      name: "authName",
      label: "真实姓名",
      component: "input",
      maxlength: 30,
      required: true,
      placeholder: "请输入",
      visible: true,
      rules: [{
        validator: () => {
          if (!formState.authName || /\d+|\w+|\S\s/.test(formState.authName)) {
            return "请输入真实姓名"
          }
          return true;
        }
      }]
    },
    {
      name: "mobile",
      label: "手机号",
      component: "input",
      maxlength: 30,
      required: false,
      readonly: true,
      placeholder: "请输入",
      visible: true,
    },
    {
      name: "email",
      label: "邮箱",
      component: "input",
      maxlength: 50,
      required: true,
      placeholder: "请输入",
      visible: true,
      rules: [{
        validator: () => {
          const mailPattern = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
          if (!formState.email) {
            return "请输入邮箱";
          } else if (!mailPattern.test(formState.email)) {
            return "请检查邮箱格式是否正确"
          }
          return true;
        }
      }]
    },
    {
      name: "nation",
      label: "民族",
      component: "select",
      maxlength: 20,
      required: true,
      placeholder: "请选择",
      visible: true,
      options: nationOptions,
      rules: [{
        validator: () => {
          if (!formState.nation) {
            return "请选择民族"
          }
          return true;
        }
      }]
    },
    {
      name: "politicalAffiliation",
      label: "政治面貌",
      required: true,
      component: "select",
      visible: true,
      options: [
        {
          text: "群众",
          value: "1"
        },
        {
          text: "中共党员",
          value: "2"
        },
        {
          text: "共青团员",
          value: "3"
        },
      ],
      show: false,
      rules: [{
        validator: () => {
          if (!formState.politicalAffiliation) {
            return "请选择政治面貌"
          }
          return true;
        }
      }]
    },
    {
      name: "liveCity",
      label: "所在城市",
      text: "",
      component: "area",
      show: false,
      visible: true,
      required: true,
      provinceIdKey: "provinceId",
      cityIdKey: "cityId",
      areaIdKey: "districtId",
      level: 3,
      rules: [{
        validator: () => {
          if (!formState.provinceId || !formState.cityId || !formState.districtId) {
            return "请选择所在城市"
          }
          return true;
        }
      }],
      onChange: () => {
        if (userShopItems.value.find(us => us.classId == ckFieldsDesc.classId)) {
          //成考
          const notCkFields = processNotCkFields();
          const hrbFirst = fields.identityFields.find(sf => sf.name == "hrbFirst");
          const hrbMy = fields.identityFields.find(sf => sf.name == "hrbMy");
          const workProve = fields.identityFields.find(sf => sf.name == "workProve");
          const ssPhoto = fields.identityFields.find(sf => sf.name == "ssPhoto");
          const residentPermit = fields.identityFields.find(sf => sf.name == "residentPermit");
          if (!formState.cityId || !formState.residenceCityId) return;
          if (formState.cityId == formState.residenceCityId) {
            hrbFirst.visible = true;
            hrbMy.visible = true;
            workProve.visible = notCkFields.includes("workProve");
            ssPhoto.visible = notCkFields.includes("ssPhoto");
            residentPermit.visible = notCkFields.includes("residentPermit");
          } else {
            hrbFirst.visible = notCkFields.includes("hrbFirst");
            hrbMy.visible = notCkFields.includes("hrbMy");
            workProve.visible = true;
            ssPhoto.visible = true;
            residentPermit.visible = true;
          }
        }
      }
    },
    {
      name: "residenceCity",
      label: "户籍城市",
      text: "",
      component: "area",
      show: false,
      visible: true,
      provinceIdKey: 'residenceProvinceId',
      cityIdKey: 'residenceCityId',
      required: true,
      rules: [{
        validator: () => {
          if (!formState.residenceProvinceId || !formState.residenceCityId) {
            return "请选择户籍城市"
          }
          return true;
        }
      }],
      onChange: () => {
        if (userShopItems.value.find(us => us.classId == ckFieldsDesc.classId)) {
          //成考
          const notCkFields = processNotCkFields();
          const hrbFirst = fields.identityFields.find(sf => sf.name == "hrbFirst");
          const hrbMy = fields.identityFields.find(sf => sf.name == "hrbMy");
          const workProve = fields.identityFields.find(sf => sf.name == "workProve");
          const ssPhoto = fields.identityFields.find(sf => sf.name == "ssPhoto");
          const residentPermit = fields.identityFields.find(sf => sf.name == "residentPermit");
          if (!formState.cityId || !formState.residenceCityId) return;
          if (formState.cityId == formState.residenceCityId) {
            hrbFirst.visible = true;
            hrbMy.visible = true;
            workProve.visible = notCkFields.includes("workProve");
            ssPhoto.visible = notCkFields.includes("ssPhoto");
            residentPermit.visible = notCkFields.includes("residentPermit");
          } else {
            hrbFirst.visible = notCkFields.includes("hrbFirst");
            hrbMy.visible = notCkFields.includes("hrbMy");
            workProve.visible = true;
            ssPhoto.visible = true;
            residentPermit.visible = true;
          }
        }
      }
    },
    {
      name: "workUnit",
      label: "工作单位",
      component: "input",
      maxlength: 50,
      required: true,
      placeholder: "请输入",
      visible: true,
    },
    {
      name: "job",
      label: "职业",
      component: "select",
      maxlength: 20,
      required: true,
      placeholder: "请选择",
      visible: true,
      options: jobOptions,
      rules: [{
        validator: () => {
          if (!formState.job) {
            return "请选择职业"
          }
          return true;
        }
      }]
    },
    {
      name: "nowAddress",
      label: "现居住地址",
      inputType: "textarea",
      component: "input",
      maxlength: 200,
      required: true,
      placeholder: "请输入",
      visible: true,
      rules: [{
        validator: () => {
          if (!formState.nowAddress) {
            return "请输入现居住地址"
          }
          var regStr = /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/ig;
          if (regStr.test(formState.nowAddress)) {
            return "请勿输入emoji表情等无效字符"
          }
          return true;
        }
      }]
    },
  ],
  HdEducationFields: [
    {
      name: "classInfoList",
      label: "报读信息"
    },
  ],
  identityFields: [
    {
      name: "authNum",
      label: "证件号码",
      component: "input",
      maxlength: 100,
      required: true,
      placeholder: "请输入",
      visible: true,
      rules: [{
        validator: () => {
          if (!formState.authNum) {
            return "请输入证件号码"
          }
          return true;
        }
      }]
    },
    {
      name: "identityAddress",
      label: "身份证地址",
      component: "input",
      inputType: "textarea",
      maxlength: 200,
      required: true,
      placeholder: "请输入",
      visible: true,
      rules: [{
        validator: () => {
          if (!formState.identityAddress) {
            return "请输入身份证地址"
          }
          var regStr = /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/ig;
          if (regStr.test(formState.identityAddress)) {
            return "请勿输入emoji表情等无效字符"
          }
          return true;
        }
      }]
    },
    {
      name: "idCard",
      label: "证件照片",
      visible: true,
      required: false,
    },
    {
      name: "oneWhileInch",
      label: "1寸白底照片",
      text: "",
      component: "image",
      required: false,
      visible: true,
    },
    {
      name: "twoWhileInch",
      label: "2寸白底照片",
      text: "",
      component: "image",
      required: false,
      visible: true,
    },
    {
      name: "twoBlueInch",
      label: "2寸蓝底照片",
      text: "",
      component: "image",
      required: false,
      visible: true,
    },
    {
      name: "hrbFirst",
      label: "户口本(首页)",
      text: "",
      component: "image",
      required: false,
      placeholder: "请上传清晰的首页扫描件",
      visible: true,
    },
    {
      name: "hrbMy",
      label: "户口本(本人)",
      text: "",
      component: "image",
      required: false,
      placeholder: "请上传清晰的本人页扫描件",
      visible: true,
    },
    {
      name: "workProve",
      label: "工作证明",
      text: "",
      component: "image",
      required: false,
      visible: true,
    },
    {
      name: "socialCity",
      label: "社保缴交城市",
      text: "",
      component: "area",
      show: false,
      required: false,
      provinceIdKey: 'ssProvinceId',
      cityIdKey: 'ssCityId',
      visible: true,
    },
    {
      name: "ssPhoto",
      label: "社保缴交证明",
      text: "",
      component: "image",
      required: false,
      placeholder: "请上传6个月社保缴交证明",
      visible: true,
    },
    {
      name: "medicalCard",
      label: "医保卡",
      text: "",
      component: "image",
      required: false,
      visible: true,
    },
    {
      name: "residentPermit",
      label: "居住证",
      text: "",
      component: "image",
      required: false,
      visible: true,
    },
  ],
  educationFields: [
    {
      name: "lastEd",
      label: "前置学历",
      required: true,
      component: "select",
      visible: true,
      options: [
        {
          text: "小学",
          value: "小学"
        },
        {
          text: "初中",
          value: "初中"
        },
        {
          text: "中专",
          value: "中专"
        },
        {
          text: "高中",
          value: "高中"
        },
        {
          text: "专科",
          value: "专科"
        },
        {
          text: "本科",
          value: "本科"
        },
        {
          text: "硕士研究生",
          value: "硕士研究生"
        },
        {
          text: "博士研究生",
          value: "博士研究生"
        }
      ],
      show: false,
      rules: [{
        validator: () => {
          if (!formState.lastEd) {
            return "请选择前置学历"
          }
          return true;
        }
      }]
    },
    {
      name: "lastEdMajor",
      label: "前置学历专业",
      component: "input",
      maxlength: 30,
      required: false,
      placeholder: "请输入",
      visible: true,
    },
    {
      name: "lastEdPhoto",
      label: "前置学历毕业证书",
      text: "",
      component: "image",
      required: false,
      visible: true,
    },
    {
      name: "lastEdTable",
      label: "前置学历电子注册备案表",
      text: "",
      help: true,
      helpAction: () => {
        showFilingsModal.value = true;
      },
      component: "image",
      required: false,
      visible: true,
      labelWidth: '5.6rem'
    },
    {
      name: "bachelorPhoto",
      label: "本科学位证书",
      text: "",
      component: "image",
      required: false,
      visible: true,
    },
    {
      name: "degreePhoto",
      label: "学位认证报告",
      text: "",
      component: "image",
      required: false,
      visible: true,
    },
    {
      name: "isPets",
      label: "是否通过公共英语三级",
      required: true,
      component: "select",
      options: [
        {
          text: "是",
          value: 1
        },
        {
          text: "否",
          value: 0
        },
      ],
      show: false,
      visible: true,
      labelWidth: '2.8rem',
      rules: [{
        validator: () => {
          if (!formState.isPets && formState.isPets !== 0) {
            return "请选择是否通过公共英语三级"
          }
          return true;
        }
      }]
    },
    {
      name: "isCet4",
      label: "是否通过大学英语四级",
      required: true,
      component: "select",
      visible: true,
      labelWidth: '2.8rem',
      options: [
        {
          text: "是",
          value: 1
        },
        {
          text: "否",
          value: 0
        },
      ],
      show: false,
      rules: [{
        validator: () => {
          if (!formState.isCet4 && formState.isCet4 !== 0) {
            return "请选择是否通过大学英语四级"
          }
          return true;
        }
      }]
    },
    {
      name: "isCct",
      label: "是否通过全国计算机等级考试",
      required: true,
      visible: true,
      component: "select",
      options: [
        {
          text: "是",
          value: 1
        },
        {
          text: "否",
          value: 0
        },
      ],
      show: false,
      labelWidth: '2.8rem',
      rules: [{
        validator: () => {
          if (!formState.isCct && formState.isCct !== 0) {
            return "请选择是否通过全国计算机等级考试"
          }
          return true;
        }
      }]
    },
    {
      name: "studyTime",
      label: "每天学习时间",
      required: true,
      visible: true,
      component: "select",
      options: [
        {
          text: "30分钟以内",
          value: "1"
        },
        {
          text: "30~60分钟",
          value: "2"
        },
        {
          text: "1~2小时",
          value: "3"
        },
        {
          text: "2~3小时",
          value: "4"
        },
        {
          text: "3小时以上",
          value: "5"
        },
      ],
      show: false,
      rules: [{
        validator: () => {
          if (!formState.studyTime) {
            return "请选择每天学习时间"
          }
          return true;
        }
      }]
    },
    {
      name: "archivesCity",
      label: "学籍档案所在地",
      text: "",
      component: "area",
      show: false,
      visible: true,
      required: false,
      provinceIdKey: 'archivesProvinceId',
      cityIdKey: 'archivesCityId',
    },
    {
      name: "adjustStatus",
      label: "是否接受调剂",
      required: true,
      visible: true,
      component: "select",
      options: [
        {
          text: "是",
          value: "1"
        },
        {
          text: "否",
          value: "2"
        },
      ],
      show: false,
      rules: [{
        validator: () => {
          if (!formState.adjustStatus) {
            return "请选择是否接受调剂"
          }
          return true;
        }
      }]
    },
  ],
  bankCardFields: [
    {
      name: "onlineBankingOpen",
      label: "网银开通情况",
      required: true,
      visible: true,
      component: "select",
      options: [
        {
          text: "已开建行网银",
          value: "1"
        },
        {
          text: "已开兴业银行网银",
          value: "2"
        },
        {
          text: "已开招商银行网银",
          value: "5"
        },
        {
          text: "已开交通银行网银",
          value: "6"
        },
        {
          text: "已开其他银行网银",
          value: "3"
        },
        {
          text: "未开通网银",
          value: "4"
        },
      ],
      show: false,
      rules: [{
        validator: () => {
          if (!formState.onlineBankingOpen) {
            return "请选择"
          }
          return true;
        }
      }]
    }
  ],
  deliveryFields: [
    {
      name: "deliveryArea",
      label: "省市区",
      text: "",
      component: "area",
      show: false,
      visible: true,
      required: true,
      provinceIdKey: "deliveryProvinceId",
      cityIdKey: "deliveryCityId",
      areaIdKey: "deliveryAreaId",
      level: 3,
      rules: [{
        validator: () => {
          if (!formState.deliveryProvinceId || !formState.deliveryCityId || !formState.deliveryAreaId) {
            return "请选择物流地址省市区"
          }
          return true;
        }
      }],
      onChange: () => {
        //
      }
    },
    {
      name: "deliveryAddress",
      label: "详细地址",
      component: "input",
      inputType: "textarea",
      maxlength: 200,
      required: true,
      placeholder: "请输入",
      visible: true,
      rules: [{
        validator: () => {
          if (!formState.deliveryAddress) {
            return "请输入物流详细地址"
          }
          var regStr = /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/ig;
          if (regStr.test(formState.deliveryAddress)) {
            return "请勿输入emoji表情等无效字符"
          }
          return true;
        }
      }]
    },
  ]
});

/**modal switchers */
const showFilingsModal = ref(false);
const showConfirmModal = ref(false);
const editable = ref(false);
const formRef = ref();
const formState = reactive({
  id: 0,
  /**基本信息 */
  authName: "",//姓名
  mobile: "", //手机
  email: "",  //邮箱
  politicalAffiliation: null,//政治面貌
  provinceId: 0,//所在城市-省份
  cityId: 0,//所在城市-城市
  districtId: 0,//所在县（区）-县（区）
  residenceProvinceId: 0,//户籍城市-省份
  residenceCityId: 0,//户籍城市-城市
  nowAddress: "",
  workUnit: "",
  job: "",
  /**意向信息 */
  intentionList: [],
  /**哈德学籍信息 */
  classInfoList: [],
  /**证件信息 */
  authNum: "",
  identityAddress: "",//身份证地址
  idCardFront: null,//身份证人像
  idCardBack: null,//身份证国徽
  oneWhileInch: "",//1寸白底照片
  twoWhileInch: "",//2寸白底照片
  twoBlueInch: "",//2寸蓝底照片
  hrbFirst: "",//户口本首页
  hrbMy: "",//户口本本人页
  workProve: "",//工作证明
  ssProvinceId: 0,//社保缴交-省份
  ssCityId: 0,//社保缴交-城市
  ssPhoto: "",//社保缴费证明
  medicalCard: "",//医保卡
  residentPermit: "",//居住证
  adjustStatus:0,

  /*学历信息 */
  lastEd: "",//前置学历
  lastEdMajor: "",//前置学历专业
  lastEdPhoto: "",//前置学历毕业证书
  lastEdTable: "",//前置学历注册备案表
  bachelorPhoto: "",//本科学位证书
  degreePhoto: "",//学位认证报告
  isPets: null,//是否通过公共英语三级
  isCet4: null,//是否通过大学英语四级
  isCct: null,//是否通过全国计算机等级考试
  studyTime: null,//每天学习时间
  archivesProvinceId: 0,//学籍档案所在地-省份
  archivesCityId: 0,//学籍档案所在地-城市
  /**银行卡信息 */
  onlineBankingOpen: null,//网银开通情况
  /**物流地址 */
  deliveryProvinceId: 0,
  deliveryCityId: 0,
  deliveryAreaId: 0,
  deliveryAddress: ""
})

const targetFields = ref([]);
const basicInfoFields = computed(() => {
  return fields.basicInfoFields.filter(sf => targetFields.value.includes(sf.name));
})
const identityFields = computed(() => {
  return fields.identityFields.filter(sf => targetFields.value.includes(sf.name));
})
const bankCardFields = computed(() => {
  return fields.bankCardFields.filter(sf => targetFields.value.includes(sf.name));
})
const deliveryFields = computed(() => {
  return fields.deliveryFields.filter(sf => targetFields.value.includes(sf.name));
})
const educationFields = computed(() => {
  return fields.educationFields.filter(sf => targetFields.value.includes(sf.name));
})
const onCopyLink = async () => {
  try {
    await toClipboard("https://my.chsi.com.cn/archive/index.jsp")
    Toast("复制成功");
  } catch (e) {
    Toast("复制失败");
  }
}

const onSubmit = () => {
  if(route.params.userId) return;
  formRef.value.validate().then(async () => {
    showConfirmModal.value = true;
  }).catch(errorList => {
    if (errorList && errorList.length) {
      const { name, message } = errorList[0];
      if (name) {
        formRef.value.scrollToField(name)
      }
      if (message) {
        Toast(message);
      }
    }
  });
}
const onBeforeConfirm = async action => {
  if (action == "confirm") {
    const postData = { ...formState };
    if (targetFields.value.length) {
      targetFields.value.map(tf => {
        const field = fields.basicInfoFields.find(sf => sf.name == tf)
          || fields.identityFields.find(ef => ef.name == tf)
          || fields.educationFields.find(ef => ef.name == tf)
          || fields.bankCardFields.find(ef => ef.name == tf)
          || fields.deliveryFields.find(ef => ef.name == tf)
        if (field) {
          if (field.name == "idCard") {
            postData.idCardFront = formState.idCardFront ? formState.idCardFront.id : "";
            postData.idCardBack = formState.idCardBack ? formState.idCardBack.id : "";
          } else if (field.component == "input" || field.component == "select") {
            if (field.name == "deliveryAddress") {
              postData.userAddress = postData.userAddress || {};
              postData.userAddress.address = formState[field.name];
            } else {
              postData[field.name] = formState[field.name];
            }
          } else if (field.component == "image") {
            postData[field.name] = formState[field.name] ? formState[field.name]["id"] : "";
          } else if (field.component == "area") {
            if (field.name == "deliveryArea") {
              postData.userAddress = postData.userAddress || {};
              postData.userAddress.provinceId = formState[field.provinceIdKey];
              postData.userAddress.cityId = formState[field.cityIdKey];
              postData.userAddress.districtId = formState[field.areaIdKey];
            } else {
              postData[field.provinceIdKey] = formState[field.provinceIdKey];
              postData[field.cityIdKey] = formState[field.cityIdKey];
              if (field.level == 3) {
                postData[field.areaIdKey] = formState[field.areaIdKey];
              }
            }
          }
        }
      })
    }
    if (formState.id) {
      postData.id = formState.id;
    }
    postData.userId = userId.value;
    try {
      const id = await submitStatusInfo(postData);
      formState.id = id;
      const statusInfoPersist = await getStatusInfoByUserId(userId.value);
      Object.assign(formState, statusInfoPersist);
      if (statusInfoPersist.userAddress) {
        formState.deliveryProvinceId = statusInfoPersist.userAddress.provinceId;
        formState.deliveryCityId = statusInfoPersist.userAddress.cityId;
        formState.deliveryAreaId = statusInfoPersist.userAddress.districtId;
        formState.deliveryAddress = statusInfoPersist.userAddress.address;
      }
      processTargetFields();
      Toast("保存成功");
      editable.value = false;
      return true;
    } catch (e) {
      return false;
    }
  }
  return true;
}
const fetchNationOptionsRemote = async ()=>{
  const list = await fetchNationOptions();
  if(list && list.length){
    nationOptions.value = list.map(({value})=>{
      return {
        text:value,
        value,
      }
    });
  }else{
    nationOptions.value = [];
  }
}
const fetchJobOptionsRemote = async ()=>{
  const list = await fetchJobOptions();
  if(list && list.length){
    jobOptions.value = list.map(({value})=>{
      return {
        text:value,
        value,
      }
    });
  }else{
    jobOptions.value = [];
  }
}
onMounted(() => {
  setTimeout(async () => {
    await waitToken(2500);
    const routeUserId = route.params.userId;
    if (routeUserId) {
      userId.value = routeUserId;
    } else {
      const { user } = localStorage['user'] ? JSON.parse(localStorage['user']) : { user: { info: {} } };
      if (user.info.id) {
        userId.value = user.info.id;
      } else {
        const userInfo = await fetchUserInfo();
        userId.value = userInfo.data.id;
      }
    }
    const orderInfos = await getOrderInfoByUserId(userId.value);
    fetchNationOptionsRemote();//获取民族选项
    fetchJobOptionsRemote();//获取职业选项
    if (orderInfos && orderInfos.length) {
      userShopItems.value = [...orderInfos];
    }
    
    const statusInfoPersist = await getStatusInfoByUserId(routeUserId);
    dataLoaded.value = true;
    if (!statusInfoPersist) {
      editable.value = true;
    } else {
      Object.assign(formState, statusInfoPersist);
      if (statusInfoPersist.userAddress) {
        formState.deliveryProvinceId = statusInfoPersist.userAddress.provinceId;
        formState.deliveryCityId = statusInfoPersist.userAddress.cityId;
        formState.deliveryAreaId = statusInfoPersist.userAddress.districtId;
        formState.deliveryAddress = statusInfoPersist.userAddress.address;
      }
      const liveCity = fields.basicInfoFields.find(sf => sf.name == "liveCity");
      liveCity.onChange();
      const residenceCity = fields.basicInfoFields.find(sf => sf.name == "residenceCity");
      residenceCity.onChange();
    }
    if (userShopItems.value.length) {
      let shopItems = [];
      //根据大类匹配
      const partCsDesc = classSubject.filter(cs => cs.catalog == "part");//大类部分匹配
      userShopItems.value.map(usi => {
        const matchedShopItems = classSubject.filter(cs => cs.catalog != "part" && cs.type != 'default').filter(cs => usi.classId == cs.classId && (cs.catalog == "all" || cs.projectId.includes(String(usi.projectId))));
        if (matchedShopItems && matchedShopItems.length) {
          shopItems = shopItems.concat(matchedShopItems);
        } else {
          if (partCsDesc && partCsDesc.length) {
            partCsDesc.map(pcd => {
              if (usi.classId == pcd.classId) {
                shopItems.push(pcd);
              }
            })
          }
        }
      });
      let shopFields = [];
      if (shopItems && shopItems.length) {
        shopItems.map(si => {
          shopFields = shopFields.concat(si.fields);
        })
        shopFields = Array.from(new Set(shopFields));
        targetFields.value = [...shopFields];
      } else {
        const defaultFields = classSubject.find(cs => cs.type == "default");
        targetFields.value = [...defaultFields.fields];
      }
    } else {
      const defaultFields = classSubject.find(cs => cs.type == "default");
      targetFields.value = [...defaultFields.fields];
    }
    //console.log("targetFields",targetFields.value)
    processTargetFields();
  }, 300);
})
const processNotCkFields = () => {
  let shopFields = [];
  if (userShopItems.value.length) {
    let shopItems = [];
    //根据大类匹配
    const partCsDesc = classSubject.filter(cs => cs.catalog == "part");//大类部分匹配
    userShopItems.value.filter(us => us.classId != ckFieldsDesc.classId).map(usi => {
      const matchedShopItems = classSubject.filter(cs => cs.catalog != "part" && cs.type != 'default').filter(cs => usi.classId == cs.classId && (cs.catalog == "all" || cs.projectId.includes(String(usi.projectId))));
      if (matchedShopItems && matchedShopItems.length) {
        shopItems = shopItems.concat(matchedShopItems);
      } else {
        if (partCsDesc && partCsDesc.length) {
          partCsDesc.map(pcd => {
            if (usi.classId == pcd.classId) {
              shopItems.push(pcd);
            }
          })
        }
      }
    });

    if (shopItems && shopItems.length) {
      shopItems.map(si => {
        shopFields = shopFields.concat(si.fields);
      })
      shopFields = Array.from(new Set(shopFields));
    }
  }
  return shopFields;
};
const processTargetFields = () => {
  targetFields.value.map(tf => {
    const field = fields.basicInfoFields.find(sf => sf.name == tf)
      || fields.educationFields.find(ef => ef.name == tf)
      || fields.identityFields.find(ef => ef.name == tf)
      || fields.deliveryFields.find(ef => ef.name == tf);
    if (!field) return false;
    if (field && field.component == "image" && formState[tf]) {
      formState[tf].persist = true;
      field.text = "已上传";
    } else if (field.name == "idCard") {
      if (formState.idCardFront) {
        formState.idCardFront.persist = true;
        field.text = "已上传";
      }
      if (formState.idCardBack) {
        formState.idCardBack.persist = true;
        field.text = "已上传";
      }
    }
  })
}
provide("formState", formState);
provide("editable", editable);
</script>
<style lang="scss" scoped>
.status-info {

  :deep(.filings-dialog.fixedpx) {
    .van-action-bar-button {
      width: 250px !important;
      height: 38px !important;
      font-size: 14px !important;
    }
  }

  :deep(.van-cell) {
    padding: var(--van-cell-vertical-padding) 30px var(--van-cell-vertical-padding) 40px !important;

    &::after {
      display: none;
    }

    &:not(.van-cell--clickable) {
      padding-right: 60px !important;
    }

    .van-cell__right-icon {
      font-size: 28px;
      color: #ccc !important;
    }

    .van-field__body {
      input {
        font-size: 28px;
      }
    }

    .van-field__error-message {
      text-align: right;
    }
  }



  .btn-block {
    display: flex;
    padding: 12px 50px 10px 50px;
    justify-content: center;
    position: sticky;
    box-sizing: border-box;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #fff;

    .btn {
      color: #fff;
      background-color: #1E4290;
      width: 650px;
      height: 88px;
      line-height: 88px;
      font-size: 32px;
      border-radius: 42px;
      border-color: #fff;
      margin: 20px auto 0 auto;
    }
  }

  .confirm-text {
    padding: 20px 40px;
  }

  .pop-filling-content {
    max-height: 760px;
    overflow-y: auto;
  }

  :deep(.van-dialog) {
    border-radius: 20px !important;
    overflow: hidden;
  }
}

.section-title {
  background-color: #1E4290;
  font-size: 28px;
  font-weight: bold;
  color: #fff;
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0 40px;

  .icon {
    width: 40px;
    height: 40px;
    margin-right: 20px;

    &.info {
      background: url('~@/assets/student/statusInfo/info.png') no-repeat;
      background-position: center center;
      background-size: 32px 32px;
    }

    &.intention {
      background: url('~@/assets/student/statusInfo/intention.png') no-repeat;
      background-position: center center;
      background-size: 32px 30px;
    }

    &.education {
      background: url('~@/assets/student/statusInfo/education.png') no-repeat;
      background-position: center center;
      background-size: 36px 32px;
    }

    &.identity {
      background: url('~@/assets/student/statusInfo/identity.png') no-repeat;
      background-position: center center;
      background-size: 32px 24px;
    }

    &.cert {
      background: url('~@/assets/student/statusInfo/cert.png') no-repeat;
      background-position: center center;
      background-size: 30px 32px;
    }

    &.card {
      background: url('~@/assets/student/statusInfo/card.png') no-repeat;
      background-position: center center;
      background-size: 32px 28px;
    }

    &.location {
      background: url('~@/assets/student/statusInfo/location.png') no-repeat;
      background-position: center center;
      background-size: 26px 32px;
    }
  }

  .text {
    flex: 1;
  }
}
</style>