import request from '@/utils/request'
import { AxiosPromise } from 'axios'

//预约日历
export function calendarData(id): AxiosPromise {
  return request({
    url: `/hd-api/os/app/student-certificate/pre-reservation-data/${id}`,
    method: 'get'
  })
}

//我的证书详情
export function getCertificateInfo(id:number): AxiosPromise {
  return request({
    url: `/hd-api/os/app/student-certificate/info/${id}`,
    method: 'get'
  })
}

//我的证书列表
export function getCertificateList(params): AxiosPromise {
  return request({
    url: `/hd-api/os/app/student-certificate/page`,
    method: 'post',
    data:params
  })
}

//默认地址
export function defaultAddress(id:number): AxiosPromise {
  return request({
    url: `/hd-api/os/app/user-address/default-address`,
    method: 'get'
  })
}

//他人领取
export function otherReceive(params): AxiosPromise {
  return request({
    url: `/hd-api/os/app/student-certificate/other-receive`,
    method: 'post',
    data:params
  })
}

//本人领取
export function myselfReceive(params): AxiosPromise {
  return request({
    url: `/hd-api/os/app/student-certificate/myself-receive`,
    method: 'post',
    data:params
  })
}

//邮寄领取
export function mailReceive(params): AxiosPromise {
  return request({
    url: `/hd-api/os/app/student-certificate/mail-receive`,
    method: 'post',
    data:params
  })
}

//获取签约数据
export function certificateContract(id): AxiosPromise {
  return request({
    url: `/hd-api/os/app/student-certificate/certificate-contract-info/${id}`,
    method: 'get'
  })
}

//确认获取
export function confirmReceiveMail(params): AxiosPromise {
  return request({
    url: `/hd-api/os/app/student-certificate/confirmReceive`,
    method: 'post',
    data:params
  })
}

//重新签约
export function cancelReservation(params): AxiosPromise {
  return request({
    url: `/hd-api/os/app/student-certificate/cancel/${params.id}`,
    method: 'get',
  })
}