
import {computed, defineComponent, onMounted, ref} from 'vue'
import {useRoute, useRouter} from "vue-router";
import {useStore} from 'vuex'
import {allSchool, createdOrder, detail, loadMajorList, unpaidOrder} from '@/api/serviceFee'
import {
  CellGroup,
  Checkbox,
  CheckboxGroup,
  DatetimePicker,
  Dialog,
  Field,
  Form,
  Picker,
  Popup,
  Search,
  Toast,
} from 'vant';
import AreaSelector from "@/components/AreaSelector.vue"
import commonJs from "@/utils/common";

export default defineComponent({
  name: 'ServiceFeeBuy',
  components: {
    Form,
    Field,
    CellGroup,
    Popup,
    Checkbox,
    CheckboxGroup,
    Picker,
    DatetimePicker,
    AreaSelector,
    Search
  },
  props: {},
  setup() {
    const store = useStore()
    let planText = ref('')
    const router = useRouter()
    const route = useRoute()
    let serviceFeeInfo:any = ref({
      itemList :[],
      dossierInfo :{}
    })
    let allSchoolList:any = ref([]);
    let schoolList:any = ref([]);

    const showPlan = ():void=>{
      Dialog.alert({
        title: '服务费说明',
        message:planText.value,
        confirmButtonColor:'#157CFF',
        messageAlign:'left'
      }).then(() => {
        // on close
      });
    }
    const id = route.query.id;
    const showSex = ref(false)
    const showBirthday = ref(false)
    const showArea =  ref(false)
    const areaShow = ref('')

    const showSchool =  ref(false)
    const schoolShow = ref('')
    const schoolSearchValue = ref('')

    const showMajor =  ref(false)
    const majorShow = ref('')
    const majorSearchValue = ref('')
    let allMajorList:any = ref([]);
    let majorList:any = ref([]);


    const showGraduationDate =  ref(false);
    let graduationDateShow = ref('');
    let graduationDate = new Date();

    let minDate = new Date(1900,0,1);

    const showIsSeniorSchoolDossier =  ref(false);
    let isSeniorSchoolDossierShow = ref('');

    const showIsReceiveDossier =  ref(false);
    let isReceiveDossierShow = ref('');

    let submitText = ref('去结算')
    //表单数据
    let checked = ref([]);
    let birthday = new Date();
    let area = ref([]);
    let schoolId = ref();
    let majorId = ref();
    let isSeniorSchoolDossier = ref();
    let isReceiveDossier = ref();

    // 生命周期
    onMounted(async () => {
      console.log(id);
      let res = await unpaidOrder({id:id})
      if(!res['unpaid']){
        //没有未支付的订单
        init()
      }else{
        //  跳转到确认支付页面
        router.push({
          name:'ServiceFeePaySure',
          query:{
            orderNumber: res['orderNumber'],

          }
        })
      }
    })
    const init = async()=>{
      serviceFeeInfo.value = await detail({id:id});
      allSchoolList.value = await allSchool();
      allSchoolList.value = allSchoolList.value
          .map(val => {
            let bean = val;
            bean.text = val.name;
            return bean;
          });
      schoolList.value = allSchoolList.value;
      if (serviceFeeInfo.value.dossierInfo.birthday){
        const dateString = serviceFeeInfo.value.dossierInfo.birthday;
        const parts = dateString.split('-');
        let year = parseInt(parts[0], 10)
        let month = parseInt(parts[1], 10) -1
        let day = parseInt(parts[2], 10)
        birthday.setFullYear(year,month,day)
      }
      if (serviceFeeInfo.value.remark){
        planText.value = serviceFeeInfo.value.remark;
      }

    }
    const onSexConfirm = (value) =>{
      serviceFeeInfo.value.dossierInfo.sex = value
      showSex.value = false;
      console.log(serviceFeeInfo)
    }
    const onBirthdayConfirm = (value) =>{
      birthday = value;
      const year = birthday.getFullYear();
      const month = birthday.getMonth() + 1;
      const day = birthday.getDate();
      serviceFeeInfo.value.dossierInfo.birthday = year
          + "-" + month.toString().padStart(2, '0')
          + "-" + day.toString().padStart(2, '0');
      showBirthday.value = false;
    }
    const onAreaConfirm = (data) => {
      area.value = data;
      showArea.value = false;
    };
    const onSchoolConfirm = async(data) => {
      showSchool.value = false;
      schoolShow.value = data.text;
      if (schoolId.value != data.id){
        majorId.value = null;
        majorShow.value = "";
      }
      schoolId.value = data.id;
      if (data.id){
        allMajorList.value = await loadMajorList({id: data.id});
        allMajorList.value = allMajorList.value
            .map(val => {
              let bean = val;
              bean.text = val.majorName + "(" + val.layerName + ")";
              return bean;
            });
        majorList.value = allMajorList.value;
        console.log(majorList);
      } else {
        allMajorList.value = [];
        majorList.value = [];
      }
    };

    const computePrice = computed(()=>{
      if(!serviceFeeInfo.value || !serviceFeeInfo.value.itemList){
        return 0
      }
      let objArr = serviceFeeInfo.value.itemList.filter(item=>{
        return checked.value.includes(item.id)
      })
      return objArr.reduce((total, v) => {
        return total + parseFloat(v.itemPrice)
      }, 0)
    })
    const submit = async ()=>{

      if(!computedSubmit().flag){
        Toast(computedSubmit().msg)
        return
      }
      let formData = ref({
        id: null,
        itemIds: [],
        dossierInfo:{
          authName: "",
          sex: "",
          authNum: "",
          birthday: null,
          provinceId: null,
          cityId: null,
          collegeId: null,
          majorId: null,
          graduationDate: null,
          isSeniorSchoolDossier: null,
          isReceiveDossier: null,
        }
      });
      if (id == null){
        Toast("未找到收费服务ID")
        return
      }
      formData.value.id = id;
      formData.value.itemIds = checked.value;
      if (serviceFeeInfo.value.dossierInfo.authName == null || serviceFeeInfo.value.dossierInfo.authName == ""){
        Toast("请输入姓名")
        return
      }
      formData.value.dossierInfo.authName = serviceFeeInfo.value.dossierInfo.authName;
      if (serviceFeeInfo.value.dossierInfo.authNum == null || serviceFeeInfo.value.dossierInfo.authNum == ""){
        Toast("请输入身份证号码")
        return
      }
      if(!commonJs.validateCertificate(serviceFeeInfo.value.dossierInfo.authNum)){
        Toast("请输入正确的身份证号")
        return
      }
      formData.value.dossierInfo.authNum = serviceFeeInfo.value.dossierInfo.authNum;
      if (serviceFeeInfo.value.dossierInfo.sex == null || serviceFeeInfo.value.dossierInfo.sex == ""){
        Toast("请选择性别")
        return
      }
      formData.value.dossierInfo.sex = serviceFeeInfo.value.dossierInfo.sex;
      if (serviceFeeInfo.value.dossierInfo.birthday == null || serviceFeeInfo.value.dossierInfo.birthday == ""){
        Toast("请选择出生日期")
        return
      }
      formData.value.dossierInfo.birthday = serviceFeeInfo.value.dossierInfo.birthday;
      if (area.value == null || area.value.length == 0){
        Toast("请选择所在省市")
        return
      }
      formData.value.dossierInfo.provinceId = area.value[0];
      formData.value.dossierInfo.cityId = area.value[1];
      if (schoolId.value == null){
        Toast("请选择毕业院校")
        return
      }
      formData.value.dossierInfo.collegeId = schoolId.value;
      if (majorId.value == null){
        Toast("请选择毕业专业")
        return
      }
      formData.value.dossierInfo.majorId = majorId.value;
      if (graduationDateShow.value == null || graduationDateShow.value == "") {
        Toast("请选择毕业日期")
        return
      }
      formData.value.dossierInfo.graduationDate = graduationDateShow.value;
      if (isSeniorSchoolDossier.value == null || isSeniorSchoolDossier.value == "") {
        Toast("请选择是否有普高或中职档案")
        return
      }
      formData.value.dossierInfo.isSeniorSchoolDossier = isSeniorSchoolDossier.value.id;
      if (isReceiveDossier.value == null || isReceiveDossier.value == "") {
        Toast("请选择继续教育档案领取情况")
        return
      }
      formData.value.dossierInfo.isReceiveDossier = isReceiveDossier.value.id;
      console.log(formData)

      if(!checked.value.length){
        Toast("请勾选至少一项费用")
        return
      }
      if (checked.value.length > 16) {
        Toast("费用数量不可超过16项")
        return
      }

      let data = await createdOrder(formData.value);
      router.push({
        name: 'ServiceFeePaySure',
        query: {
          orderNumber: data['orderNumber']
        }
      })
    }
    const logout = ()=>{
      Dialog.confirm({
        title: '提示',
        message:'确定要退出登录？',
        confirmButtonColor:'#157CFF',
      })
          .then(async () => {
            store.dispatch('Logout')
          })
          .catch(() => {
            // on cancel
          });

    }
    const computedSubmit = () => {
      let msg, flag
      let status = serviceFeeInfo.value.status;
      if (status != 1) {
        submitText.value = '已下架'
        msg = '服务费已下架'
        flag = false
      } else {
        submitText.value = '去结算'
        msg = ''
        flag = true
      }
      return {
        flag,
        msg
      }
    }
    const schoolOnSearch  = ()=> {
      schoolList.value = allSchoolList.value.filter(item=> {
        return item.text.includes(schoolSearchValue.value);
      });
    }

    const onMajorConfirm = (data) => {
      showMajor.value = false;
      majorShow.value = data.text;
      majorId.value = data.id;
    };

    const majorOnSearch  = ()=> {
      majorList.value = allMajorList.value.filter(item=> {
        return item.text.includes(majorSearchValue.value);
      });
    }
    const onGraduationDateConfirm = (value) =>{
      graduationDate = value;
      const year = graduationDate.getFullYear();
      const month = graduationDate.getMonth() + 1;
      const day = graduationDate.getDate();
      graduationDateShow.value = year
          + "-" + month.toString().padStart(2, '0')
          + "-" + day.toString().padStart(2, '0');
      showGraduationDate.value = false;
    }
    const onIsSeniorSchoolDossierConfirm = (data) => {
      isSeniorSchoolDossier.value = data;
      isSeniorSchoolDossierShow.value = data.text;
      showIsSeniorSchoolDossier.value = false;
    };
    const onIsReceiveDossierConfirm = (data) => {
      isReceiveDossier.value = data;
      isReceiveDossierShow.value = data.text;
      showIsReceiveDossier.value = false;
    };
    // 暴露给模板
    return {
      showPlan,
      checked,
      showSex,
      onSexConfirm,
      submit,
      computePrice,
      submitText,
      computedSubmit,
      logout,
      serviceFeeInfo,
      birthday,
      onBirthdayConfirm,
      showBirthday,
      areaShow,
      showArea,
      onAreaConfirm,
      area,
      showSchool,
      schoolShow,
      schoolId,
      onSchoolConfirm,
      schoolList,
      schoolOnSearch,
      schoolSearchValue,
      majorShow,
      showMajor,
      majorSearchValue,
      onMajorConfirm,
      majorOnSearch,
      majorList,
      showGraduationDate,
      graduationDateShow,
      graduationDate,
      onGraduationDateConfirm,
      minDate,
      isReceiveDossier,
      isSeniorSchoolDossier,
      showIsReceiveDossier,
      showIsSeniorSchoolDossier,
      isReceiveDossierShow,
      isSeniorSchoolDossierShow,
      onIsSeniorSchoolDossierConfirm,
      onIsReceiveDossierConfirm
    }
  },
})
