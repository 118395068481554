// 收费api

import request from '@/utils/request'
import { AxiosPromise } from 'axios'

export function unpaidOrder(params): AxiosPromise {
  return request({
    url: `/hd-api/os/app/order/service-fee/unpaid-order/${params.id}`,
    method: 'get',
  })
}
export function detail(params): AxiosPromise {
  return request({
    url: `/hd-api/os/app/order/service-fee/detail/${params.id}`,
    method: 'get',
  })
}
export function allSchool(): AxiosPromise {
  return request({
    url: "/hd-api/os/user/auth/college/select-all",
    method: 'get',
  })
}

export function loadMajorList(params): AxiosPromise {
  return request({
    url: `/hd-api/os/user/auth/college/${params.id}/major/list`,
    method: 'post',
  })
}

export function createdOrder(params): AxiosPromise {
  return request({
    url: `/hd-api/os/h5/order/down-order/service-fee`,
    method: 'post',
    data: params,
  })
}

export function getOrderDetail(orderNumber): AxiosPromise {
  return request({
    url: `/hd-api/os/app/order/service-fee/order-detail/${orderNumber}`,
    method: 'get',
  })
}

