import request from '@/utils/request'
import { AxiosPromise } from 'axios'

export function getOrderInfoByUserId(userId): AxiosPromise {
    return request({
        url: `/hd-api/os/user/student/residence/order/${userId}`,
        method: 'get',
    })
}
export function getStatusInfoByUserId(id): AxiosPromise {
    return request({
        url: '/hd-api/os/user/student/residence/h5' + (id ? `/${id}`:''),
        method: 'get',
    })
}
export function submitStatusInfo(data): AxiosPromise {
    return request({
        url: `/hd-api/os/user/student/residence/command/h5`,
        method: 'put',
        data:data
    })
}
export function fetchNationOptions(): AxiosPromise {
    return request({
        url: `/hd-api/os/user/student/query/nation`,
        method: 'get',
    })
}
export function fetchJobOptions(): AxiosPromise {
    return request({
        url: `/hd-api/os/user/student/query/job`,
        method: 'get',
    })
}